<template>
  <div class="zaixun">
    <div class="jieshao">
      “灾汛预警通”是汉康针对山洪、地质等灾害监测行业应用特点和实际需求为出发点开发出的一套移动信息化灾害预警系统。它基于互联网和移动通信技术，将地理信息技术、无线数据同步传输技术运用到预警信息管理中来，完善了原有的群测群防灾害监测体系，增强了决策者、管理者、监测责任人、灾区群众获取山洪、地质灾害信息的时效性，为“科学防灾、监测预警、临灾避险”提供了信息保障。
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/31.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据汇集</div>
      <div class="li-text">
        完成平台所辖各监测站的水雨情信息的实时接收、处理和入库。对其它相关部门的共享与交换信息经处理后按规定的数据库表结构存入数据库中。具有采集数据监控分析、水雨情信息汇集、数据上报和外部数据共享与整合等功能
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">灾汛服务</div>
      <div class="li-text">
        主要为决策部门、系统维护管理等部门提供基于WEB方式的各类数据信息的查询服务。具体包括：基础信息查询、实时水雨情监视、气象国土等相关部门信息服务等功能。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">灾汛上传</div>
      <div class="li-text">
        当发生灾害事件时，现场人员可以通过手机终端将现场情况以图片或视频的方式上传到服务器，这样指导决策人员可通过平台软件直观的看到现场的具体情况。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">灾汛发布</div>
      <div class="li-text">
        管理人员可以在视图（地图）上通过点选相应的灾害点或监测点进行信息查询,也可以通过关键字查询定位地标信息以及灾害信息。同时，可以查看监测仪器的实时和历史数据。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">预警响应</div>
      <div class="li-text">
        预警响应跟踪是根据预警结果、信息发布情况以及各级政府批准的响应预案，各级相关部门要启动相应的响应预案。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">统计分析</div>
      <div class="li-text">
        管理人员可以在视图（地图）上通过点选相应的灾害点或监测点进行信息查询,也可以通过关键字查询定位地标信息以及灾害信息。同时，可以查看监测仪器的实时和历史数据。
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">发讯方式</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/32.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据统计</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/33.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/34.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">灾汛上传</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/35.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.zaixun {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>