<template>
  <div class="dizhi">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/27.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据采集</div>
      <div class="li-text">
        动态数据（水位、雨量、位移、含水率等）
        <br />静态数据（工程治理、基础地理、运营保障等）
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">灾害监测分析</div>
      <div class="li-text">
        信息汇聚 <br />预警预报 <br />辅助决策 <br />地灾信息三维分析
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">应急指挥决策</div>
      <div class="li-text">
        预案管理 <br />应急评估 <br />联动指挥 <br />应急准备
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">信息服务</div>
      <div class="li-text">
        信息发布 <br />出行指南 <br />地图导航 <br />综合查询
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">群策群防</div>
      <div class="li-text">
        宣传栏 <br />警示牌 <br />培训演练 <br />移动终端
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据中心</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/28.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">预警指挥调度</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/29.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">地质灾害监测预警</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/30.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.dizhi {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>